import React, { useState } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import './left.css'
import { Link as ScrollLink, Events } from 'react-scroll';

const LeftContent = () => {
  const [activeLink, setActiveLink] = useState('description');

  // Function to handle section change
  const handleSetActive = (section) => {
    setActiveLink(section);
  }
  

  // Register the scroll event listener
  Events.scrollEvent.register('begin', function(to) {
    handleSetActive(to);
  });

  return (
    <div className="left-content"> {/* Apply the class for styling */}
      <h1>Andrew Liu</h1>
      <p>Natural Language Processing & Neural Networks</p>
      <nav>
        <ul>
        <li><ScrollLink activeClass={activeLink === 'description' ? 'active' : ''} to="description" spy={true} smooth={true} duration={500} offset={20} onSetActive={() => handleSetActive('description')}>whois?</ScrollLink></li>
        <li><ScrollLink activeClass={activeLink === 'experience' ? 'active' : ''} to="experience" spy={true} smooth={true} duration={500} offset={-window.innerHeight * 0.02} onSetActive={() => handleSetActive('experience')}>EXPERIENCE</ScrollLink></li>
        <li><ScrollLink activeClass={activeLink === 'projects' ? 'active' : ''} to="projects" spy={true} smooth={true} duration={500} offset={-window.innerHeight * 0.075} onSetActive={() => handleSetActive('projects')}>PROJECTS</ScrollLink></li>

        </ul>
      </nav>
      <div className="social-links">
        <a href="https://github.com/ahl1u" target="_blank" rel="noreferrer">
          <i className="fa fa-github" aria-hidden="true"></i>
        </a>
        <a href="https://www.linkedin.com/in/andrew-liu04/" target="_blank" rel="noreferrer">
          <i className="fa fa-linkedin" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  );
}

export default LeftContent
