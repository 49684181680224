import React from "react"
import { Helmet } from "react-helmet"
import "./layout.css"
import LeftContent from "./left"

const Layout = ({ children }) => (
  <div className="layout">
    <Helmet>
      <link href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap" rel="stylesheet" />
    </Helmet>
    <div className="container">
      <div className="left-content">
        <LeftContent />
      </div>
      <div className="right-content">
        {children}
      </div>
    </div>
  </div>
)

export default Layout
