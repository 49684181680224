import React from "react"
import Layout from "./layout"
import Description from "./description"
import Projects from "./projects"
import Experience from "./experience"
import { Analytics } from '@vercel/analytics/react';

const IndexPage = () => (
  
  <Layout>
    <Analytics />
    <div style={{ marginBottom: "75px" }}>
      <Description />
    </div>
    <div style={{ marginBottom: "75px" }}>
      <Experience />
    </div>
    <div>
      <Projects />
    </div>
  </Layout>
)

export default IndexPage
