import React from "react"
import './description.css'

const Description = () => (
    <div id="description">
      <p>Hey there! I'm a sophmore at UNC studying CS + Business. </p>
  
      <p>I enjoy software development and am currently exploring building projects with AI. I've also done research related to <span style={{ color: '#5aadff', fontWeight: "bold" }}>Binary Expansion Testing</span> and <span style={{ color: '#5aadff', fontWeight: "bold" }}>Sentiment Analysis</span> with ML models.</p> 
      
      <p>This site showcases some of the projects and experiences I've completed and am currently involved in.
      </p>
      </div>
  )
  
export default Description
