import React from "react"
import './projects.css'

const Projects = () => (
<div id="projects">
<a href="https://www.classmate.lol" target="_blank" rel="noopener noreferrer" className="project-section-link">
  <div className="project-section">
    <div className="project-image">
      <img className="project-image" src={`./classmate.jpg`} alt="Project Description" />
    </div>
    <div className="project-details">
      <div className="project-item">
        <h3>classmate<a href="#" target = "_blank" className="link-buttonp">↗</a></h3>
        <p>Created a React.js web application for UNC students to create, view, and share class schedules and campus events. 
            Utilized ChakraUI libraries for front-end development and Firebase for real-time data storage and authentication. 
            The website, supporting 12.5k unique users, includes features like schedule customization, friend connections, and event tracking. </p>
        <div className="badges">
            <span className="badge">React.js</span>
            <span className="badge">HTML</span>
            <span className="badge">CSS</span>
            <span className="badge">ChakraUI</span>
            <span className="badge">Firebase</span>
            <span className="badge">Mixpanel</span>
        </div>
      </div>
    </div>
  </div>
  </a>

  <a href="https://github.com/ahl1u/battleship" target="_blank" rel="noopener noreferrer" className="project-section-link">
  <div className="project-section">
    <div className="project-image">
      <img className="project-image" src={`./bship.jpg`} alt="Another Project Description" />
    </div>
    <div className="project-details">
      <div className="project-item">
        <h3>Battleship<a href="#" target = "_blank" className="link-buttonp">↗</a></h3>
        <p>Battleship game, built with TypeScript, CSS, React, and Next.js, 
            offers a player-vs-computer mode following classic rules on a 10x10 grid. 
            Inspired by data scientist Nick Berry's strategies, it features a simplified 'Hunt and Attack' 
            algorithm without employing the full complexities of Berry's probability analyses.</p>
        <div className="badges">
            <span className="badge">Typescript.js</span>
            <span className="badge">React.js</span>
            <span className="badge">CSS</span>
            <span className="badge">Next.js</span>
        </div>
      </div>
    </div>
  </div>
  </a>
  <div className="project-section">
    <div className="project-image">
      <img className="project-image" src={`./bship.jpg`} alt="Another Project Description" />
    </div>
    <div className="project-details">
      <div className="project-item">
        <h3>Battleship<a href="#" target = "_blank" className="link-buttonp">↗</a></h3>
        <p>Battleship game, built with TypeScript, CSS, React, and Next.js, 
            offers a player-vs-computer mode following classic rules on a 10x10 grid. 
            Inspired by data scientist Nick Berry's strategies, it features a simplified 'Hunt and Attack' 
            algorithm without employing the full complexities of Berry's probability analyses.</p>
        <div className="badges">
            <span className="badge">Typescript.js</span>
            <span className="badge">React.js</span>
            <span className="badge">CSS</span>
            <span className="badge">Next.js</span>
        </div>
      </div>
    </div>
  </div>

  <div className="github-link">
    <a href="https://github.com/ahl1u?tab=repositories" target="_blank" rel="noopener noreferrer">
    View All Projects
    </a>
</div>

</div>

)
export default Projects
