import React from "react"
import './experience.css'

const Experience = () => (
  <div id="experience">
    <a href="https://www.sas.rochester.edu/dsc/undergraduate/reu.html" target="_blank" rel="noopener noreferrer" className="experience-section-link">
    <div className="experience-section">
        
        <div className="timeline">
            <p>May 2023 - Present</p>
        </div>
        <div className="experience-details">
            <div className="experience-item">
                <h3>Research Fellow &middot; University of Rochester <a href="#" target = "_blank" className="link-button">↗</a></h3>
                <p>Participate in a highly selective NSF-funded ML-based program, 
                    analyzing synthetic cooling agents' perceptions on Twitter. 
                    Trained and utilized machine learning models for sentiment analysis + user classifcation.
                    Research presented @ symposium, and abstract accepted into the SRNT conference</p>
                        <div className="badges">
                            <span className="badge">Python</span>
                            <span className="badge">roBERTa</span>
                            <span className="badge">BERTopic</span>
                            <span className="badge">nltk</span>
                            <span className="badge">sklearn</span>
                            <span className="badge">pandas</span>
                            <span className="badge">Matplotlib</span>
                            <span className="badge">plotly</span>
                </div>
            </div>
        </div>
        </div>
        </a>

        <a href="https://www.classmate.lol" target="_blank" rel="noopener noreferrer" className="experience-section-link">
        <div className="experience-section">
        
        <div className="timeline">
            <p>Apr 2023 - Present</p>
        </div>
        <div className="experience-details">
            <div className="experience-item">
            <h3>Co-Founder &middot; classmate.lol <a href="#" className="link-button">↗</a></h3>
                <p>Co-founded a startup that currently allows UNC students to create and view their own class schedules, their friends' class schedules, and campus events. 
                    The platform has reached 12.5k unique users with 350+ daily users post-registration cycle, 
                    enhancing schedule organization and student connections. I currently oversee the financial, marketing, 
                    and development aspects, with expansion plans to other universities. </p>
                        <div className="badges">
                            <span className="badge">React.js</span>
                            <span className="badge">HTML</span>
                            <span className="badge">CSS</span>
                            <span className="badge">ChakraUI</span>
                            <span className="badge">Firebase</span>
                            <span className="badge">Mixpanel</span>
                </div>
            </div>
        </div>

        </div>
        </a>
        <a href="https://towardsdatascience.com/checking-for-dependency-using-binary-expansion-testing-bet-5234a818a18" target="_blank" rel="noopener noreferrer" className="experience-section-link">
        <div className="experience-section">
        
        <div className="timeline">
            <p>Aug 2022 - Present</p>
        </div>
        <div className="experience-details">
            <div className="experience-item">
                <h3>Undergraduate Researcher &middot; UNC-CH <a href="#" className="link-button">↗</a></h3>
                <p>I manage Java data analytics software using Binary Expansion Testing (BET) to test large datasets for dependence. 
                    My tasks included big data processing, coding efficiency improvements, troubleshooting, student support, 
                    and website design for NSF-supported projects. Additionally, I engaged in literature reviews, data analysis,
                    and attended seminars on statistics and data science.</p>
                        <div className="badges">
                            <span className="badge">R Studio</span>
                            <span className="badge">Python</span>
                            <span className="badge">BET</span>
                </div>
            </div>
        </div>

        </div>
        </a>
        <div className="resume-link">
            <a href="/resume_site.pdf" target="_blank" rel="noopener noreferrer">
            Download Full Resume
            </a>
        </div>
  </div>
)
export default Experience
